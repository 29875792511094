import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { Template, useApps, useBusy } from '@seedbin/ui-commons';

import { useEffect } from 'react';

interface Application {
  type: string;
  name: string;
  description: string;
  path: string;
}

const applications: Application[] = [
  {
    type: 'ats',
    name: 'Available To Ship',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur cursus mi orci, vitae tristique lacus tincidunt eu. Aliquam posuere, augue sit amet varius rhoncus, lacus metus placerat sapien, a rhoncus nibh mauris et leo. Nunc a tincidunt justo. Fusce egestas ante massa, quis euismod justo tincidunt a. Etiam libero dui, tristique non accumsan non, luctus a tellus. Aliquam finibus accumsan erat, et egestas erat malesuada quis. Vestibulum venenatis urna non mi pulvinar mattis. Aliquam vulputate sodales nisi. In egestas laoreet erat eget tincidunt. Curabitur eu ligula vel ipsum vulputate aliquet.',
    path: '/ats/',
  },
  {
    type: 'pfd',
    name: 'Breeding',
    description:
      'The Breeding module provides comprehensive management of pre foundation seed production from crop planning to shipment. Functionalities include user management (access, roles, permissions, and preferences), crop planning, field planning, inventory management, shipping management, field data, sample processing, quality data integration, data warehousing, and reporting.',
    path: '/pfd/',
  },
  {
    type: 'uaa',
    name: 'Global User Management',
    description:
      'Nulla facilisi. Aliquam malesuada laoreet diam vel condimentum. Nam orci eros, luctus at posuere sit amet, viverra a eros. Proin vel odio sit amet quam consectetur tempor et in neque. Nam est felis, fermentum ut sem ac, interdum hendrerit mauris. Cras laoreet, ante cursus egestas ultricies, ipsum odio sagittis quam, ac posuere erat massa viverra dui. Fusce eget odio gravida, facilisis erat sit amet, hendrerit urna. Aliquam lobortis felis ac sapien mattis, eget venenatis tortor varius. Donec finibus pulvinar metus eu tincidunt.',
    path: '/uaa/',
  },
];

export function Landing(): JSX.Element | null {
  const apps = useApps();

  const busy = useBusy();

  const availableApps =
    apps && applications.filter(({ type }) => apps.has(type));

  useEffect(() => {
    if (availableApps === undefined || availableApps?.length === 1) {
      busy.start(true);
    } else {
      busy.stop();
    }
    if (availableApps?.length === 1) {
      window.location.href = availableApps[0].path;
    }
  }, [availableApps, busy]);

  if (availableApps === undefined || availableApps?.length === 1) {
    return null;
  }

  return (
    <Template title="Bayer Modules" helpButton={null}>
      {availableApps.length === 0 ? (
        <Typography variant="body1" color="error">
          You have access to no modules.
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {availableApps.map(({ name, description, path }) => (
            <Grid item xl={3} lg={4} md={6} sm={12} key={path}>
              <Card>
                <CardActionArea component={Link} href={path} underline="none">
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Template>
  );
}
